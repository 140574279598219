import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'

import * as styles from '../components/products.module.css'

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo
          title="Products"
          description="Take a look and explore my creations."
          image="/og-image.png"
        />
        <div className={styles.background}>
          <header className={styles.productsHeader}>
            <div className={styles.container}>
              <h1>Products</h1>
              <p>Take a look and explore my creations.</p>
            </div>
          </header>
          <ArticlePreview posts={posts} />
        </div>
      </Layout>
    )
  }
}

//TODO: add styling + container header products

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        heroImage {
          gatsbyImage(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          raw
        }
      }
    }
  }
`
